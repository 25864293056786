import Layout, { Content } from 'antd/lib/layout/layout';
import { Home } from './containers/Home/Home';
import { About } from './containers/About/About';
import { BusinessPlan } from './containers/BusinessPlan/BusinessPlan';
import './App.less';
import Partner from './containers/Partner/Partner';
import { Roadmap } from './containers/Roadmap/Roadmap';
import { AboutIc8 } from './containers/AboutIc8/AboutIc8';
import { LetUs } from './containers/LetUs/LetUs';
import { Contact } from './containers/Contact/Contact';
import { Ratity } from './containers/Ratity/Ratity';

function App() {
  return (
    <Layout className='bg-landing-page'>
      {/* <AppHeader /> */}
      <Content>
        <Home />
        <AboutIc8 />
        <Roadmap />
        <Ratity />
        <LetUs />
        <Partner />
        <Contact />
      </Content>
    </Layout>
  );
}

export default App;
