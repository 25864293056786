import { Button } from 'antd'
import { isMobile } from 'mobile-device-detect'
import React, { useState } from 'react'

const ConnectWallet = () => {
    const [address, setAddress] = useState(null)
    const connectWalletHandle = async () => {
        if (!address)
            if (window.ethereum) {
                window.ethereum.enable().then((accounts) => {
                    setAddress(accounts[0])
                });
            } else {
                window.open("https://metamask.app.link/dapp/")
            }
    }
    return (
        <Button className='btn-wallet'>
            Login
        </Button>
    )
}

export default ConnectWallet