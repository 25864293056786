import React from 'react'
import { Row, Col, Space } from 'antd';
import "./Home.less";
import { Container } from '../../components/Container/Container';
import { AppButton } from '../../components/AppButton/AppButton';
import ConnectWallet from '../../components/AppButton/ConnectWallet';

export const Home = () => {
  return (
    <section id="home" className="home">
      <div className='menu-header'>
        <div className='menu-container'>
          <div className='flex-menu'>
            <a href="#">
              <img
                className="app-header__logo"
                src="/assets/images/logo.svg"
                alt="logo"
              />
            </a>
            <ConnectWallet />
          </div>
        </div>

      </div>
      <Container className="home__container">
        <Row className='flex-home'>
          <div className='bg__hero' >
            <div className='bg_hero_logo'>
              <div className='ic8-floating'>
                <img src="/assets/images/bg-hero-logo.svg" className='bg_hero_logo_img' alt='' />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </section >
  )
}
