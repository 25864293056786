import React from 'react'
import { Form, Col, Input, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea';

import { Container } from '../../components/Container/Container';
import { SectionHeading } from '../../components/SectionHeading/SectionHeading';

import contact from "../../assets/contact/contact.png";

import "./Contact.less";
import { AppButton } from '../../components/AppButton/AppButton';

export const Contact = () => {
  const { Item } = Form;

  return (
    <section id="contact" className="contact">
      <Container>
        <Row gutter={24} className="contact__form">
          <Col sm={24} md={12}>
            <img className="contactUs__img" src={"/assets/images/contact-us.png"} alt={"img"} style={{
              width: "100%"
            }} />
          </Col>
          <Col sm={24} lg={12}>
            <Form>
              <Row >
                <h1 className="contact__title">
                  Send us a message
                </h1>
              </Row>
              <Row className='sns__contact'>
                <img src="/assets/icons/twitter.svg" />
                <img src="/assets/icons/dis.svg" />
              </Row>
              <Row className=''>
                <p className='text__contact'>If you have any question, we would love to hear from you!</p>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Item >
                    <Input placeholder="Your name" />
                  </Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Item >
                    <Input placeholder="Your email address" />
                  </Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Item >
                    <TextArea rows={5} placeholder="Your message" />
                  </Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Item>
                    <AppButton className="btn_contact">
                      Submit
                    </AppButton>
                  </Item>
                </Col>
              </Row>
            </Form>
          </Col>

        </Row>
      </Container>
    </section >
  )
}
