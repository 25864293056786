import React, { useState } from 'react'
import { Col, Row } from 'antd';
import { Container } from '../../components/Container/Container';
import { AppButton } from '../../components/AppButton/AppButton';

import img from "../../assets/business-plan/business-img.png";
import "./Ratity.less"

export const Ratity = () => {
  const [typeSelect, setTypeSelect] = useState("Red")
  const data = {
    "color": [
      "Red", "Yellow", "Green", "Blue", "Pink"
    ],
    "Level": [
      "Common", "Uncommon", "Rare", "Mythical", "Legend", "Limited"
    ],
    "Asset": [
      "Hair", "Glasses", "Mask", "Weapon", "Spaceship"
    ]
  }

  const checkActive = (text) => {
    if (text === typeSelect) return true
    else return false
  }
  return (
    <section id="Ratity" className="ratity">
      <Container className="ratity__container">
        <div>
          <h1 className="ratity__title">
            Ratity
          </h1>
          <p className="ratity__desc">
            The IC8 collection has 5 assets (Hair, Glasses, Mask, Weapon and Spaceship). Each asset has 6 levels (Common, Uncommon, Rare, Mythical, Legend and Limited), corresponding with 5 different colors (Red, Yellow, Green, Blue, Pink).
          </p>

        </div>
        <Row gutter={64}>

          <Col sm={24} lg={12} style={{
            display: 'flex',
            alignItems: 'center'
          }}>

            <div className='raity-detail'>
              <div className='color'>
                <h4>Color</h4>
                <div className='color_btn'>
                  {
                    data["color"].map((item, index) => (
                      <button key={index} onClick={() => setTypeSelect(item)}
                        style={
                          checkActive(item) ? { background: "#572DCF" } : { background: "#6800BA" }
                        }
                      >{item}</button>
                    ))
                  }
                </div>
              </div>
              <div className='color'>
                <h4>Level</h4>
                <div className='color_btn'>
                  {
                    data["Level"].map((item, index) => (
                      <button key={index} onClick={() => setTypeSelect(item)}
                        style={
                          checkActive(item) ? { background: "#572DCF" } : { background: "#6800BA" }
                        }
                      >{item}</button>
                    ))
                  }
                </div>
              </div>
              <div className='color'>
                <h4>Asset</h4>
                <div className='color_btn'>
                  {
                    data["Asset"].map((item, index) => (
                      <button key={index} onClick={() => setTypeSelect(item)}
                        style={
                          checkActive(item) ? { background: "#572DCF" } : { background: "#6800BA" }
                        }
                      >{item}</button>
                    ))
                  }
                </div>

              </div>
            </div>
          </Col>
          <Col sm={24} lg={12}>
            <div className='space-ship'>
              <img className='character' src={"/assets/images/ratity.png"} alt={img} />
              <img src="/assets/images/character.png" alt='' className='ic8_character' />
            </div>
          </Col>

        </Row>
        <Row>
          <div>
            <AppButton className="find_asset">Find your asset</AppButton>
          </div>
        </Row>
      </Container>
    </section>
  )
}
