import { Row } from 'antd'
import React from 'react'
import { Container } from '../../components/Container/Container'
import './Partner.less'

const Partner = () => {
    return (
        <section section id="partner" className="partner" >
            <Container>

                <Row gutter={[24, 24]} justify="center">
                    <div className='box-partner'>
                        <h3 className='heading-partner'>
                            Partner
                        </h3>
                        <div className='box-body'>
                            <div>
                                <img src="/assets/icons/partner.svg" alt='' />
                            </div>
                            <div>

                                <p className='content_box'>Entrepot.app, developed by Toniqlabs, is the first NFT marketplace on the Internet Computer blockchain. It offers a pivotal role in the digital world: a point of exchange where users can store and trade digital assets in a decentralized and unregulated manner.</p>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </section>
    )
}

export default Partner