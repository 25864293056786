import React from 'react'
import { Col, Row } from 'antd';
import { Container } from '../../components/Container/Container';
import { AppButton } from '../../components/AppButton/AppButton';

import img from "../../assets/business-plan/business-img.png";
import "./Letus.less"

export const LetUs = () => {
  return (
    <section id="LetUs" className="let_us">
      <Container className="let_us__container">
        <Row gutter={64}>
          <Col sm={24} md={12}>
            <img className="let_us__img" src={"/assets/images/Let-us.png"} alt={img} />
          </Col>
          <Col sm={24} md={12} style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <div>
              <h1 className="let_us__title">
                ICE Studio - Let’s us help you open a legendary of blockchain
              </h1>
              <p className="let_us__desc">
                ICE Studio is a young generation having confidence in the development of Blockchain. Our community is built upon active operation and solid development on top social media sites.
              </p>
              <p className="let_us__desc">
                Meta8, an ecosystem created by ICE Studio, is composed of experienced developers and marketers who aim to form a well-established ecosystem where enthusiasts can reap profits on Blockchain.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noreferrer"
              >

              </a>
            </div>

          </Col>


        </Row>
      </Container>
    </section>
  )
}
