import React from 'react'
import { Col, Row } from 'antd';
import { Container } from '../../components/Container/Container';
import { AppButton } from '../../components/AppButton/AppButton';

import img from "../../assets/business-plan/business-img.png";
import "./BusinessPlan.less"

export const AboutIc8 = () => {
  return (
    <section id="AboutIce" className="aboutIc8">
      <Container className="aboutIc8__container">
        <Row gutter={64}>
          <Col sm={24} md={12} style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <div>
              <h1 className="aboutIc8__title">
                What Is IC8 - Meta 8 Project ?
              </h1>
              <p className="aboutIc8__desc">
                IC8 contains 8888 Infinity Chickens in the Meta 8, the play-to-earn gaming project based on the Internet Computer Protocol platform. In addition, Meta 8 offers plenty of rewards to IC8 NFT holders. You can also earn more NFTs and ICP tokens by joining Metaverse.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noreferrer"
              >

              </a>
            </div>

          </Col>
          <Col sm={24} md={12}>
            <img className="aboutIc8__img" src={"/assets/images/about-ic8-right.png"} alt={img} />
          </Col>

        </Row>
      </Container>
    </section>
  )
}
