import React, { useState } from 'react'
import { Col, Row } from 'antd';
import { Container } from '../../components/Container/Container';
import styled from 'styled-components';
import "./Roadmap.less"
import { isMobile } from 'mobile-device-detect';
const StepsStyled = styled.div`
   &::before {
      content: "";
      position: absolute;
      width: ${(props) => props.w || "35%"};
      background: #FFF;
     border: 2px solid #2F5DFF;
      top: 0;
      left: 0;
      height: 100%;
    }
`
export const Roadmap = () => {
  const [wActive, setWActive] = useState("35%")
  const [currentStep, setCurrentStep] = useState(1)
  return (
    <section id="Roadmap" className="roadmap-plan">
      <Container className="roadmap-plan__container">
        <h1 className="roadmap-heading">
          Roadmap
        </h1>
        <Row gutter={64} style={{ paddingTop: "20px" }}>
          <Col sm={24} md={8} style={{
            width: "100%"
          }}>
            <div className='roadmap__right'>
              <div className='btn__phase active'>
                <div className='active_bg' />
                <div className='title'>Phase one</div>
                <div className='content'>Building Foundation</div>
              </div>
              <div className='btn__phase'>
                <div className='title'>Phase two</div>
                <div className='content'>Growth</div>
              </div>
              <div className='btn__phase'>
                <div className='title'>Phase three</div>
                <div className='content'>Extension & Future</div>
              </div>
            </div>
          </Col>
          <Col sm={24} md={16}>
            <div className='time__line'>
              <div>
                <img src='/assets/icons/roadmap-right.svg' />
                <span className='roadmap-plan__title '>Q1 2022</span>
              </div>
              <div className='roadmap-plan__desc'>
                Started producing NFTs. After that, introduce IC8 project to community. Next, whitelist early supporters. Once the price has been determined by community voting, NFTs will be launched shortly. After selling all these, reserve 25% of the sales in Infinity Space Station treasury. In the future, contribute periodic profits to it. Launch Date: March 8th 2022.
              </div>
            </div>
            <div className='time__line active'>
              <div>
                <img src='/assets/icons/roadmap-right.svg' />
                <span className='roadmap-plan__title '>Q3 2022</span>
              </div>
              <div className='roadmap-plan__desc'>
                Q2 2022 - Launch 8888 pets in gameplay. Create 8888 pets (will be named later) in future GameFi. Then, add the active project supporters to Whitelist, who can claim 444 free pets. After that, launch another 4000 pets on Entrepot.app. Finally, store the remaining 4444 pets in testnet server.
              </div>
            </div>
            <div className='time__line'>
              <div>
                <img src='/assets/icons/roadmap-right.svg' />
                <span className='roadmap-plan__title '>Q3 2022</span>
              </div>
              <div className='roadmap-plan__desc'>
                Q3 Q4 2022: Build NFTs gameplay using Infinity Chickens and pets according to the later-developed concept by ICE Studio. Then, airdrop testnet slots to active contributors (*) . This means they could claim the 4444 pets (free of charge) in testnet server.
                (*)active contributors: people supporting our project fervently, for example, by engaging with project contests, helping other members and/or speaking favorably of our project on media sites.
              </div>
            </div>
            <div className='time__line'>
              <div>
                <img src='/assets/icons/roadmap-right.svg' />
                <span className='roadmap-plan__title '>Q4 2022</span>
              </div>
              <div className='roadmap-plan__desc'>
                IC8 holders to receive free 2D NFTs for collectible purposes. The more 3D NFTs they own, the more 2D NFTs they will be given later.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section >
  )
}
